body {
  background: #000;
  color: #fff;
  margin: 0;
  font-family: 'Roboto Mono', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

#root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
}

#canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  pointer-events: none;
}

.video-quiz {
  position: relative;
  display: grid;
  grid-template-columns: 215px 1fr 250px;
  grid-template-rows: 1fr 120px;
  width: 1280px;
  height: 720px;
  overflow: hidden;
  background: var(--bgcolor);
  font-family: 'Raleway', sans-serif;

  .video-box {
    grid-column: 2 / span 2;
    grid-row: 1;

    .video {
      position: relative;
      width: 100%;
      height: 100%;
    }

    video {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .video-caption {
      position: absolute;
      left: 25px;
      bottom: 10px;
      color: var(--videoCaption);
      text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
      font-size: 16px;
      line-height: 1.2;
      animation: 0.4s linear 1s both fadeIn;
    }
  }

  .score-box {
    grid-column: 2 / span 2;
    grid-row: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    color: var(--scoreText);
    overflow: hidden;
    position: relative;

    .fail {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .fail-text {
      font-size: 2em;
      text-transform: uppercase;
      opacity: 0;
      text-align: center;
    }

    .fail-image {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transform: translateY(100%);

      .fix-bg-cache {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: var(--failImg);
      }
    }

    .user-score-inner {
      padding: 6px 0;
      display: flex;
      align-items: center;
      width: 920px;
      border-bottom: 1px solid var(--scoreBorder);
      max-width: 100%;
    }

    .user-avatar {
      margin-right: 20px;
      height: 40px;
      width: 40px;

      img {
        display: block;
        width: 40px;
        height: auto;
      }
    }

    .user-name {
      flex: 1 1 auto;
      text-transform: uppercase;
    }

    .user-points {
      color: var(--scorePoints);
    }
  }

  .status-box {
    position: relative;
    z-index: 3;
    grid-column: 2 / -1;
    grid-row: 2;
    text-transform: uppercase;
    line-height: 1.1;
    perspective: none;

    .status-box-inner {
      position: relative;
      width: 100%;
      height: 100%;
      transform-style: preserve-3d;
      transform: translateZ(-60px);
    }

    .status {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      overflow: hidden;
      color: var(--statusText);
      font-size: 40px;
      font-style: italic;
      font-weight: bold;

      svg {
        height: 33px;
        width: auto;
        fill: var(--statusText);
        display: inline-block;
        position: relative;
        top: 2px;
        left: -15px;
      }
    }

    .status-text {
      text-align: center;
      font-weight: bold;
      padding: 0 10px;
    }
  }

  .answer-options {
    grid-column: 3;
    grid-row: 1;
    position: relative;
    color: var(--optionColorDefault);
    counter-reset: answer;
    z-index: 2;

    .answer-options-inner {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      height: 100%;
    }

    .option {
      flex: 1 1 0;
      width: 100%;
      display: flex;
      align-items: center;
      font-size: 32px;
      position: relative;
      counter-increment: answer;
      transform: translateX(calc(100% - 10px));

      &::before {
        content: counter(answer, upper-latin);
        position: absolute;
        right: 10px;
        bottom: -5px;
        color: var(--optionCounter);
        font-size: 70px;
      }

      &:nth-child(4n+1) {
        background: var(--optionBg1);
        color: var(--optionColor1);
      }

      &:nth-child(4n+2) {
        background: var(--optionBg2);
        color: var(--optionColor2);
      }

      &:nth-child(4n+3) {
        background: var(--optionBg3);
        color: var(--optionColor3);
      }

      &:nth-child(4n+4) {
        background: var(--optionBg4);
        color: var(--optionColor4);
      }
    }

    .option-inner {
      position: relative;
      z-index: 2;
      width: 100%;
    }

    .option-text {
      width: 100%;
      padding: 10px 10px 10px 15px;
    }
  }

  .counter-down {
    grid-column: 2;
    grid-row: 1;
    position: relative;
    z-index: 1;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    font-family: 'Roboto Mono', 'Helvetica Neue', sans-serif;
    font-weight: bolder;

    .circle {
      position: relative;
      width: 2.1em;
      height: 2.1em;
      font-size: 50px;
      line-height: 1;

      .ripple {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0.7;
        will-change: transform;
      }

      .ripple-inner {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: var(--counterBg);
      }
    }

    .wrap {
      position: relative;
      z-index: 2;
      color: var(--counterColor);
      width: 100%;
      height: 100%;
      text-align: center;
      border-radius: 50%;
      will-change: transform;

      .number {
        margin-top: -6px;
      }

      .wrap-inner {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--counterBg);
      }
    }
  }

  .leaderboard {
    grid-column: 1;
    grid-row: 1;
    background: var(--leaderboardBg);
    color: var(--leaderboardColor);
    counter-reset: place;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;

    h2 {
      padding: 10px 10px 10px 10px;
      text-align: center;
      background: var(--leaderboardTitleBg);
      margin: 0 0 2px;
      color: var(--leaderboardTitleColor);
    }

    .best-scores {
      position: relative;
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      margin-bottom: -2px;
    }

    .user-score {
      counter-increment: place;
      background: var(--leaderboardBg);
      flex: 0 1 52.2px;
    }

    .user-score-inner {
      padding: 27px 10px 4px 10px;
      position: relative;
      border-bottom: 1px solid var(--leaderboardUserBorder);

      &::before {
        position: absolute;
        z-index: 2;
        content: counter(place);
        left: 55px;
        top: 0;
        font-size: 27px;
        line-height: 1;
        color: var(--leaderboardUserCounter);
      }

      .user-name {
        display: block;
        margin-left: 45px;
        width: calc(100% - 45px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .user-avatar {
        position: absolute;
        width: 40px;
        left: 5px;
        top: 5px;
        z-index: 1;

        img {
          width: 100%;
          height: auto;
          display: block;
        }
      }

      .user-points {
        position: absolute;
        right: 15px;
        top: 9px;
        font-size: 14px;
        color: var(--leaderboardUserPoints);
      }
    }
  }

  .quiz-logo {
    grid-column: 1;
    grid-row: 2;
    background: var(--logoBg);
    color: var(--logoColor);
    font-family: 'Faster One', 'Roboto Mono', 'Helvetica Neue', sans-serif;
    font-size: 40px;
    padding: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1;
    position: relative;
    z-index: 3;

    > div {
      flex: 1 1 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: -40px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

