:root {
  --bgcolor: #000 url('./img/hogwarts.jpg');
  --videoCaption: #d6d3d3;
  --scoreText: #d6d3d3;
  --scoreBorder: #1c1a18;
  --scorePoints: #56f55a;
  --failImg: url('./img/weasely.png') center bottom / 450px auto no-repeat;
  --statusText: #f1c406;
  --optionColorDefault: #d6d3d3;
  --optionCounter: rgba(255, 255, 255, 0.7);
  --optionBg1: #a40002 url('./img/red.jpg') 0 0 / 100% 100% no-repeat;
  --optionColor1: #ffd110;
  --optionBg2: #f8f808 url('./img/yellow.jpg') 0 0 / 100% 100% no-repeat;
  --optionColor2: #251000;
  --optionBg3: #162287 url('./img/blue.jpg') 0 0 / 100% 100% no-repeat;
  --optionColor3: #f5a242;
  --optionBg4: #277659 url('./img/green.jpg') 0 0 / 100% 100% no-repeat;
  --optionColor4: #d1e5f1;
  --leaderboardBg: linear-gradient(to bottom, rgba(28, 26, 24, 0.5) 0%, rgba(28, 26, 24, 0.6) 10%, rgba(28, 26, 24, 0.7) 40%, rgba(28, 26, 24, 0.7) 60%, rgba(28, 26, 24, 0.6) 90%, rgba(28, 26, 24, 0.5) 100%);
  --leaderboardColor: #d6d3d3;
  --leaderboardTitleBg: linear-gradient(to bottom, rgba(28, 26, 24, 0.5) 0%, rgba(28, 26, 24, 0.6) 10%, rgba(28, 26, 24, 0.7) 40%, rgba(28, 26, 24, 0.7) 60%, rgba(28, 26, 24, 0.6) 90%, rgba(28, 26, 24, 0.5) 100%);
  --leaderboardTitleColor: #fff;
  --leaderboardUserBorder: rgba(0, 0, 0, 0.5);
  --leaderboardUserCounter: #d6d3d3;
  --leaderboardUserPoints: #56f55a;
  --logoBg: url('./img/potter-logo.png') 75% 100% / 550px auto no-repeat;
  --logoColor: #d6d3d3;
  --logoColor2: #d6d3d3;
}

@font-face {
  font-family: 'Harry Potter Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Harry Potter Regular'), url('./fonts/HARRP___.woff') format('woff');
}

.theme-harry-potter {
  grid-template-columns: 300px 1fr 250px;
  grid-template-rows: 170px 1fr;
  background: none;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1920px;
    height: 100%;
    background: var(--bgcolor);
    animation: 120s ease-in-out infinite alternate moveBg;
  }

  @keyframes moveBg {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-600px);
    }
  }

  .video-box {
    grid-column: 2 / -1;
    grid-row: 2 / -1;
    position: relative;
    z-index: 3;
    background: #000;

    .video-caption {
      left: auto;
      right: 7px;
      bottom: 7px;
      z-index: 10;
      font-size: 17px;
    }
  }

  &.step-count-down {

    .video-box {

      .video-caption {
        animation: 0.4s linear both fadeOut;
      }
    }

    .status-box .progress-bar {
      display: block;
    }
  }

  .leaderboard {
    grid-column: 2 / -1;
    grid-row: 2 / -1;
    background: url('./img/leaderboard-ribbon.png') left top / 210px auto repeat-y;

    h2 {
      margin: 0;
      padding: 0;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 170px;
      font-size: 54px;
      text-transform: uppercase;
    }

    .best-scores {
      background: rgba(0, 0, 0, 0.3);
      margin-left: 210px;
    }

    .user-score {
      font-size: 25px;

      &:first-child {
        padding-top: 1px;
      }
    }

    .user-score-inner {
      display: flex;
      align-items: center;
      padding: 5px 10px 5px;

      &::before {
        position: static;
        font-size: 1.6em;
        width: 1.1em;
        text-align: right;
        margin-right: 10px;
      }

      .user-avatar {
        position: static;
        width: 50px;
        height: 50px;
        flex: 0 0 auto;
      }

      .user-name {
        margin: 0 0 0 20px;
      }

      .user-points {
        position: static;
        font-size: 1em;
        white-space: nowrap;
      }
    }
  }

  .answer-options {
    grid-column: 2 / -1;
    grid-row: 2 / -1;
    z-index: 3;
    overflow: hidden;

    .option {
      position: relative;
      width: 100%;
      padding-left: 160px;
      font-size: 46px;
      transform: translateY(-100%);

      &::after {
        content: '';
        position: absolute;
        left: 35px;
        top: 0;
        width: 100px;
        height: 100%;
        opacity: 1;
        pointer-events: none;
        z-index: 0;
      }

      &:nth-child(4n+1) {
        z-index: 4;
        transform: translateY(-110%);

        &::after {
          background: url('./img/gryffindor.png') center center / contain no-repeat;
        }
      }

      &:nth-child(4n+2) {
        z-index: 3;
        transform: translateY(-210%);

        &::after {
          background: url('./img/hufflepuff.png') center center / contain no-repeat;
        }
      }

      &:nth-child(4n+3) {
        z-index: 2;
        transform: translateY(-310%);

        &::after {
          background: url('./img/ravenclaw.png') center center / contain no-repeat;
        }
      }

      &:nth-child(4n+4) {
        z-index: 1;
        transform: translateY(-410%);

        &::after {
          background: url('./img/slytherin.png') center center / contain no-repeat;
        }
      }

      .option-text {
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
      }
    }
  }

  .score-box {
    grid-row: 2 / -1;
    background: url('./img/scores-bg.png') left top/210px auto repeat-y;
    display: block;

    .winners {
      height: 100%;
      margin-left: 210px;
      background: rgba(0, 0, 0, 0.3);
    }

    &.status-fail {
      background: rgba(219, 79, 70, 0.3);

      .winners {
        background: none;
      }
    }

    .user-score-inner {
      background: var(--leaderboardBg);
      padding: 6px;
      width: 100%;
      flex: 1 1 auto;
    }

    .user-avatar {
      height: 42px;
      width: 42px;

      img {
        width: 42px;
      }
    }

    .fail {
      align-items: flex-start;
    }

    .fail-text {
      margin-top: 30px;
    }
  }

  .status-box {
    grid-column: 2 / -1;
    grid-row: 1;
    transition: background 0.3s;
    overflow: hidden;

    .status {
      font-size: 54px;
    }

    .progress-bar {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 0;
      background: #00ff00;
      opacity: 0.4;
      display: none;
    }

    .status-text {
      text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);

      svg {
        filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5));
      }
    }

    &.normal {
      background: rgba(0, 0, 0, 0.7);

      .status-text {
        color: #fff;
      }
    }

    &.info {
      background: rgba(7, 152, 255, 0.7);
    }

    &.success {
      background: rgba(29, 156, 91, 0.7);
    }

    &.warning {
      background: rgba(240, 116, 50, 0.7);
    }

    &.fail {
      background: rgba(219, 79, 70, 0.7);
    }
  }

  .quiz-logo {
    grid-row: 1 / -1;
    font-size: 51px;
    font-family: 'Harry Potter Regular', sans-serif;
    justify-content: flex-end;
    padding-bottom: 10px;
    padding-right: 100px;
    margin-right: -100px;
    z-index: 4;

    > div {
      margin-bottom: 10px;
      flex: 0;
    }

    .whitespace {
      margin: 0 5px;
    }

    .logo-line2 {
      color: var(--logoColor2);
    }

    .logo-line3 {
      font-size: 40px;
      margin: 13px 0 auto;
      justify-self: flex-start;
      color: #021222
    }
  }
}
